.Control-Buttons {
	margin: 3rem 0;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn-grp {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.btn {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	width: 10vw;
	height: 5vh;
	border-radius: 14px;
	margin: 0px 6px;
	display: flex;
	border: 2px solid #2ac3f1;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: #f5f5f5;
}

.btn-one {
	background-color: #2ab9e4;
}
.btn:hover{
	background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
   }
